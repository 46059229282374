// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'


// 获取系统消息列表
export const postListInformationNotice = (data:any) => request('/payAdminServer/information/listInformationNotice', null, 'POST_JSON', data)

// 获取系统消息单条
export const postItemInformationNotice = (data:{id:number}) => request('/payAdminServer/information/selectById', null, 'POST_JSON', data)

// 新增系统消息
export const postAddInformationNotice = (data:any) => request('/payAdminServer/information/addInformationNotice', null, 'POST_JSON', data)

// 删除系统消息
export const postDeleteInformationNotice = (data:{noticeIds:string}) => request('/payAdminServer/information/deleteInformationNotice', null, 'POST_JSON', data)

// 撤回系统消息
export const postGetBackInformationNotice = (data:{id:string,sendStatus:number}) => request('/payAdminServer/information/getBackInformationNotice', null, 'POST_JSON', data)

// 查询用户的系统消息
export const postListUserNotice = (data:any) => request('/payAdminServer/information/listUserNotice', null, 'POST_JSON', data)

// 更新系统消息
export const postUpdateInformationNotice = (data:any) => request('/payAdminServer/information/updateInformationNotice', null, 'POST_JSON', data)

// 更新系统消息已读状态
export const postUpdateUserReadNoticeStatus = (data:{userId:string,noticeIds:string}) => request(`/payAdminServer/information/updateUserReadNoticeStatus`, null, 'POST_JSON', data)

//查看所有系统消息list
export const postSelectAllUserNotice = (data:{userId:string,title?:string}) => request(`/payAdminServer/information/selectAllUserNotice`, null, 'POST_JSON', data)
