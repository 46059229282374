import "core-js/modules/es.array.push.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-455377fc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "userbook_select_wrapper"
};
const _hoisted_2 = {
  key: 0,
  class: "top_title"
};
const _hoisted_3 = {
  class: "userbook_select_container"
};
const _hoisted_4 = {
  class: "userbook_select_left"
};
const _hoisted_5 = {
  key: 0,
  class: "empty_container"
};
const _hoisted_6 = {
  key: 1,
  class: "list_container"
};
const _hoisted_7 = {
  key: 0,
  class: "user_container"
};
const _hoisted_8 = ["onClick"];
const _hoisted_9 = {
  class: "user_item_check"
};
const _hoisted_10 = {
  class: "user_item_content"
};
const _hoisted_11 = ["innerHTML"];
const _hoisted_12 = {
  key: 1,
  class: "account"
};
const _hoisted_13 = ["innerHTML"];
const _hoisted_14 = ["innerHTML"];
const _hoisted_15 = {
  key: 1,
  class: "empty_container"
};
const _hoisted_16 = {
  key: 0
};
const _hoisted_17 = {
  key: 1
};
const _hoisted_18 = {
  class: "userbook_select_right"
};
const _hoisted_19 = {
  class: "top_select_title"
};
const _hoisted_20 = {
  key: 0,
  class: "checked_user_list"
};
const _hoisted_21 = {
  key: 0
};
const _hoisted_22 = {
  key: 1
};
const _hoisted_23 = {
  class: "user_item_right_content"
};
const _hoisted_24 = {
  class: "user_item_right_content_wrapper"
};
const _hoisted_25 = {
  key: 0,
  class: "name"
};
const _hoisted_26 = {
  key: 1,
  class: "account"
};
const _hoisted_27 = {
  key: 0,
  class: "depart"
};
const _hoisted_28 = ["onClick"];
const _hoisted_29 = {
  key: 1,
  class: "empty_container"
};
import { CaretDownOutlined, CloseOutlined, ApartmentOutlined } from '@ant-design/icons-vue';
import { watch, computed, ref } from "vue";
import { Empty } from "ant-design-vue";
import { findParentIdList, formatTreeData, colorArr, charToUnicode, PREFIX_USER, getCheckedUserList, getLeafNodeCountById } from "./utils";
export default {
  __name: 'select',
  props: {
    title: {
      type: String
    },
    searchOrgListApi: {
      type: Function,
      required: true
    },
    searchUserListApi: {
      type: Function,
      required: true
    },
    replaceFields: {
      type: Object,
      default: () => ({
        children: 'children',
        title: 'orgName',
        key: 'id'
      })
    },
    markColor: {
      type: String,
      default: '#1890ff'
    },
    selectBgColor: {
      type: String,
      default: '#e8eeff'
    },
    orgIconBgColor: {
      type: String,
      default: '#3c50df'
    },
    // 左侧禁止选择的人员
    disabledUserList: {
      type: Array,
      default: () => []
    },
    // 接口參數
    paramOfApi: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['select'],
  setup(__props, {
    emit
  }) {
    const props = __props;

    // 用户是否进行了筛选
    const hasFilterOption = computed(() => {
      return !!searchValue.value.trim();
    });
    const checkedUser = computed(() => {
      // 之过滤保留用户节点
      const userList = checkedKeys.value.filter(item => {
        var _String;
        return (_String = String(item)) === null || _String === void 0 ? void 0 : _String.includes(PREFIX_USER);
      });
      // 过滤掉被禁用的节点
      const enabledUserList = userList.filter(item => {
        var _props$disabledUserLi;
        return !((_props$disabledUserLi = props.disabledUserList) !== null && _props$disabledUserLi !== void 0 && _props$disabledUserLi.map(temp => PREFIX_USER + temp).includes(item));
      });
      return enabledUserList;
    });
    // 右侧显示的选中的人员
    const checkedUserList = computed(() => {
      return getCheckedUserList(checkedUser.value, orgList.value);
    });
    const simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    const searchValue = ref(''); //搜索关键字
    const orgList = ref([]);
    const userList = ref([]);
    const checkedKeys = ref([]);
    const expandedKeys = ref([]);
    const loading = ref(true);
    const onCheck = keys => {
      checkedKeys.value = keys;
      emitSelect();
    };
    const onExpand = keys => {
      expandedKeys.value = keys;
    };
    const deleteChoosedUser = id => {
      let parentIdList = findParentIdList(orgList.value, id);
      for (let i = 0; i < parentIdList.length; i++) {
        const element = parentIdList[i];
        if (checkedKeys.value.includes(element)) {
          checkedKeys.value = checkedKeys.value.filter(item => item !== element);
        }
      }
      checkedKeys.value = checkedKeys.value.filter(item => item !== id);
      emitSelect();
    };

    // 点击筛选用户的回调
    const clickUserItem = item => {
      if (props.disabledUserList.includes(item.id)) {
        return;
      }
      let userKey = PREFIX_USER + item.id;
      if (checkedKeys.value.includes(userKey)) {
        checkedKeys.value = checkedKeys.value.filter(item => item !== userKey);
      } else {
        checkedKeys.value.push(userKey);
        updateExpandedKeys(userKey);
      }
      emitSelect();
    };
    const updateExpandedKeys = id => {
      let idList = findParentIdList(orgList.value, id);
      expandedKeys.value = [...new Set([...expandedKeys.value, ...idList])];
    };

    // 高亮选中效果
    const markText = text => {
      if (text.includes(searchValue.value)) {
        var _String2;
        return (_String2 = String(text)) === null || _String2 === void 0 ? void 0 : _String2.replace(new RegExp(searchValue.value.trim(), 'gi'), `<span style="color: ${props.markColor}">${searchValue.value}</span>`);
      }
      return text;
    };

    // 初始化组织树列表
    const initOrgList = async () => {
      return new Promise(resolve => {
        loading.value = true;
        let params = {
          ...props.paramOfApi
        };
        props.searchOrgListApi(params).then(res => {
          const {
            code,
            records
          } = res;
          if (code === '0000' && records !== null && records !== void 0 && records.length) {
            orgList.value = formatTreeData(records, props.disabledUserList) || [];
          } else {
            orgList.value = [];
          }
          resolve(orgList.value);
        }).catch(err => {
          resolve([]);
        }).finally(() => {
          loading.value = false;
        });
      });
    };

    // 搜索用户
    const searchUserList = async () => {
      try {
        if (hasFilterOption.value) {
          loading.value = true;
          const {
            code,
            records
          } = await props.searchUserListApi({
            condition: searchValue.value.trim(),
            ...props.paramOfApi
          });
          if (code === '0000') {
            userList.value = records || [];
          }
        } else {
          userList.value = [];
        }
      } catch (error) {} finally {
        loading.value = false;
      }
    };
    const emitSelect = () => {
      var _checkedUser$value, _checkedUserList$valu;
      const regex = new RegExp(PREFIX_USER, 'ig');
      const checkedUserIdList = (_checkedUser$value = checkedUser.value) === null || _checkedUser$value === void 0 ? void 0 : _checkedUser$value.map(item => item.replace(regex, ''));
      const checkedUserItemList = (_checkedUserList$valu = checkedUserList.value) === null || _checkedUserList$valu === void 0 ? void 0 : _checkedUserList$valu.map(item => {
        return {
          ...item,
          id: item.id.replace(regex, '')
        };
      });
      emit('select', checkedUserIdList, checkedUserItemList);
    };
    watch(() => props.disabledUserList, async val => {
      await initOrgList();
      if (val && val !== null && val !== void 0 && val.length) {
        const disabledCheckedList = val === null || val === void 0 ? void 0 : val.map(item => PREFIX_USER + item);
        checkedKeys.value = [...new Set([...disabledCheckedList, ...checkedKeys.value])];
      }
    }, {
      immediate: true
    });

    // watch(() => props.searchOrgListApi, (val) => {
    //     if(val){
    //         initOrgList()
    //     }
    // }, { immediate: true })

    return (_ctx, _cache) => {
      var _unref2;
      const _component_a_input_search = _resolveComponent("a-input-search");
      const _component_a_checkbox = _resolveComponent("a-checkbox");
      const _component_a_tree = _resolveComponent("a-tree");
      const _component_a_spin = _resolveComponent("a-spin");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [__props.title ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(__props.title), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_a_input_search, {
        class: "search_input",
        value: searchValue.value,
        "onUpdate:value": _cache[0] || (_cache[0] = $event => searchValue.value = $event),
        allowClear: "",
        placeholder: "搜索",
        onChange: searchUserList,
        onSearch: searchUserList
      }, null, 8, ["value"]), _createVNode(_component_a_spin, {
        spinning: loading.value
      }, {
        default: _withCtx(() => {
          var _orgList$value, _userList$value, _userList$value2;
          return [!((_orgList$value = orgList.value) !== null && _orgList$value !== void 0 && _orgList$value.length) ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_unref(Empty), {
            image: _unref(simpleImage)
          }, null, 8, ["image"])])) : (_openBlock(), _createElementBlock("div", _hoisted_6, [_withDirectives(_createElementVNode("div", null, [(_userList$value = userList.value) !== null && _userList$value !== void 0 && _userList$value.length ? (_openBlock(), _createElementBlock("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(userList.value, (item, index) => {
            return _openBlock(), _createElementBlock("div", {
              onClick: $event => clickUserItem(item),
              key: index,
              class: _normalizeClass(["user_item", {
                disabled: __props.disabledUserList.includes(item.id)
              }]),
              style: _normalizeStyle({
                backgroundColor: checkedKeys.value.includes(_unref(PREFIX_USER) + item.id) ? __props.selectBgColor : ''
              })
            }, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_a_checkbox, {
              disabled: __props.disabledUserList.includes(item.id),
              checked: checkedKeys.value.includes(_unref(PREFIX_USER) + item.id)
            }, null, 8, ["disabled", "checked"])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", null, [item.name ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "name",
              innerHTML: markText(item.name)
            }, null, 8, _hoisted_11)) : _createCommentVNode("", true), item.userName ? (_openBlock(), _createElementBlock("span", _hoisted_12, [_createTextVNode("("), _createElementVNode("span", {
              innerHTML: markText(item.userName)
            }, null, 8, _hoisted_13), _createTextVNode(")")])) : _createCommentVNode("", true)]), item.depFullName ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "depart",
              innerHTML: markText(item.depFullName)
            }, null, 8, _hoisted_14)) : _createCommentVNode("", true)])], 14, _hoisted_8);
          }), 128))])) : _createCommentVNode("", true), !((_userList$value2 = userList.value) !== null && _userList$value2 !== void 0 && _userList$value2.length) ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createVNode(_unref(Empty), {
            image: _unref(simpleImage),
            description: "没有找到结果"
          }, null, 8, ["image"])])) : _createCommentVNode("", true)], 512), [[_vShow, _unref(hasFilterOption)]]), _withDirectives(_createElementVNode("div", null, [_createVNode(_component_a_tree, {
            checkable: "",
            selectable: false,
            "tree-data": orgList.value,
            replaceFields: __props.replaceFields,
            checkedKeys: checkedKeys.value,
            "onUpdate:checkedKeys": _cache[1] || (_cache[1] = $event => checkedKeys.value = $event),
            expandedKeys: expandedKeys.value,
            "onUpdate:expandedKeys": _cache[2] || (_cache[2] = $event => expandedKeys.value = $event),
            showIcon: "",
            onCheck: onCheck,
            onExpand: onExpand
          }, {
            switcherIcon: _withCtx(() => [_createVNode(_unref(CaretDownOutlined), {
              style: {
                fontSize: '15px',
                color: '#000000a5'
              }
            })]),
            userIcon: _withCtx(({
              orgName
            }) => [_createElementVNode("span", {
              class: "user-inf-header-tree",
              style: _normalizeStyle({
                background: _unref(colorArr)[_unref(charToUnicode)(orgName)]
              })
            }, [orgName.length < 3 ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(orgName), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(orgName.substring(orgName.length - 2)), 1))], 4)]),
            title: _withCtx(({
              orgName,
              type,
              id
            }) => [_withDirectives(_createElementVNode("span", {
              class: "org_icon",
              style: _normalizeStyle({
                backgroundColor: __props.orgIconBgColor
              })
            }, [_createVNode(_unref(ApartmentOutlined))], 4), [[_vShow, type == 'org']]), _createElementVNode("span", {
              class: _normalizeClass(["org_name", {
                'org_item_name': type == 'org',
                'user_item_name': type == 'user'
              }])
            }, _toDisplayString(orgName), 3), _withDirectives(_createElementVNode("span", {
              class: "numbers"
            }, "(" + _toDisplayString(_unref(getLeafNodeCountById)(id, orgList.value)) + ")", 513), [[_vShow, type == 'org']])]),
            _: 1
          }, 8, ["tree-data", "replaceFields", "checkedKeys", "expandedKeys"])], 512), [[_vShow, !_unref(hasFilterOption)]])]))];
        }),
        _: 1
      }, 8, ["spinning"])]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, "已选：" + _toDisplayString(_unref(checkedUser).length) + " 人", 1), (_unref2 = _unref(checkedUserList)) !== null && _unref2 !== void 0 && _unref2.length ? (_openBlock(), _createElementBlock("div", _hoisted_20, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(checkedUserList), item => {
        return _openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "right_user_item"
        }, [_createElementVNode("div", {
          class: "user-right-header",
          style: _normalizeStyle({
            background: _unref(colorArr)[_unref(charToUnicode)(item.orgName)]
          })
        }, [item.orgName.length < 3 ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(item.orgName), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(item.orgName.substring(item.orgName.length - 2)), 1))], 4), _createElementVNode("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, [item.name ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(item.name), 1)) : _createCommentVNode("", true), item.userName ? (_openBlock(), _createElementBlock("div", _hoisted_26, [_createTextVNode("("), _createElementVNode("span", null, _toDisplayString(item.userName), 1), _createTextVNode(")")])) : _createCommentVNode("", true)]), item.depFullName ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(item.depFullName), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", {
          onClick: _withModifiers($event => deleteChoosedUser(item.id), ["stop"]),
          class: "right_close"
        }, [_createVNode(_unref(CloseOutlined))], 8, _hoisted_28)]);
      }), 128))])) : (_openBlock(), _createElementBlock("div", _hoisted_29, [_createVNode(_unref(Empty), {
        image: _unref(simpleImage),
        description: "暂未选择人员"
      }, null, 8, ["image"])]))])])]);
    };
  }
};