// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 获取组织架构下树形人员列表
export const getOrgListFetch = (data:any) =>
  request('/payAdminServer/userport/getNewDepWithQueryUser', null, 'POST_JSON', data)

// 获取组织架构下人员列表
export const getUserListFetch = (data:any) =>
  request('/payAdminServer/userport/getAllUsersByCondition', null, 'POST_JSON', data)

// 获取所有用户列表
export const getAllUsersList = (data:any) => request('/ProdMgntServ/uapp/getUserTree', data, 'GET', null)
